jQuery(document).ready( function() {

    var urlParams = new URLSearchParams(window.location.search);

    // Convert the URLSearchParams object to a query string
    var queryString = urlParams.toString();
    jQuery('.select-consumption div').on('click', function() {
        var level = jQuery(this).data('level');
        jQuery('.select-consumption div').removeClass('active');
        jQuery('#update-quote').hide();
        jQuery(this).addClass('active');
        if (level == 'low') {
            var typUsageElec = 1800;
            var typUsageGas = 7500;
            jQuery('.your-consumption #low-consumption').show();
            jQuery('.your-consumption #low-consumption div').show();
            jQuery('.your-consumption #low-consumption').css('display', 'flex');

            jQuery('.your-consumption #medium-consumption').hide();
            jQuery('.your-consumption #medium-consumption div').hide();

            jQuery('.your-consumption #high-consumption').hide();
            jQuery('.your-consumption #high-consumption div').hide();

            jQuery('.your-consumption #enter-consumption').hide();
            jQuery('.your-consumption #enter-consumption div').hide();

            jQuery('.your-consumption').show();
            jQuery('#elec-consumption-value').val(typUsageElec);
            jQuery('#gas-consumption-value').val(typUsageGas);
            jQuery('#elecUsagereg').val(typUsageElec);
            jQuery('#gasUsagereg').val(typUsageGas);
                      
            jQuery('#update-quote').click();
        }
        if (level == 'medium') {
            var typUsageElec = 2700;
            var typUsageGas = 11500;
            jQuery('.your-consumption #low-consumption').hide();
            jQuery('.your-consumption #low-consumption div').hide();

            jQuery('.your-consumption #medium-consumption').show();
            jQuery('.your-consumption #medium-consumption div').show();
            jQuery('.your-consumption #medium-consumption').css('display', 'flex');

            jQuery('.your-consumption #high-consumption').hide();
            jQuery('.your-consumption #high-consumption div').hide();

            jQuery('.your-consumption #enter-consumption').hide();
            jQuery('.your-consumption #enter-consumption div').hide();

            jQuery('.your-consumption').show();
            jQuery('#elec-consumption-value').val(typUsageElec);
            jQuery('#gas-consumption-value').val(typUsageGas);
            jQuery('#elecUsagereg').val(typUsageElec);
            jQuery('#gasUsagereg').val(typUsageGas);
            jQuery('#update-quote').click();
        }
        if (level == 'high') {
            var typUsageElec = 4100;
            var typUsageGas = 17000;
            jQuery('.your-consumption #low-consumption').hide();
            jQuery('.your-consumption #low-consumption div').hide();

            jQuery('.your-consumption #medium-consumption').hide();
            jQuery('.your-consumption #medium-consumption div').hide();

            jQuery('.your-consumption #high-consumption').show();
            jQuery('.your-consumption #high-consumption div').show();
            jQuery('.your-consumption #high-consumption').css('display', 'flex');

            jQuery('.your-consumption #enter-consumption').hide();
            jQuery('.your-consumption #enter-consumption div').hide();

            jQuery('.your-consumption').show();
            jQuery('#elec-consumption-value').val(typUsageElec);
            jQuery('#gas-consumption-value').val(typUsageGas);
            jQuery('#elecUsagereg').val(typUsageElec);
            jQuery('#gasUsagereg').val(typUsageGas);
            jQuery('#update-quote').click();
        }
        if (level == 'own') {
            jQuery('#update-quote').show();
            jQuery('.your-consumption #low-consumption').hide();
            jQuery('.your-consumption #low-consumption div').hide();

            jQuery('.your-consumption #medium-consumption').hide();
            jQuery('.your-consumption #medium-consumption div').hide();

            jQuery('.your-consumption #high-consumption').hide();
            jQuery('.your-consumption #high-consumption div').hide();

            jQuery('.your-consumption #enter-consumption').show();
            jQuery('.your-consumption #enter-consumption div').show();
            jQuery('.your-consumption #enter-consumption').css('display', 'flex');

            jQuery('.your-consumption').show();
        }
    });

    jQuery('.your-consumption #enter-consumption').show();
    jQuery('.your-consumption #enter-consumption').css('display', 'flex');
    jQuery('.your-consumption #enter-consumption div').show();

    jQuery('.quote-cta-tc img').click(function() {
        jQuery('#tariff-details-panel').toggle();
    }, function() {
        jQuery('#tariff-details-panel').toggle();
    });

    jQuery('#info-img').hover(function() {
        jQuery('#info-provider-panel').show();
    }, function() {
        jQuery('#info-provider-panel').hide();
    });

    jQuery('.con-enterknown').on('click', function() {
        jQuery('#low-panel').hide();
        jQuery('#medium-panel').hide();
        jQuery('#high-panel').hide();
        jQuery('.rebel-panel-wrap').hide();
        jQuery('#enter-own-consump').show();
    });

    // on hover of the info icon, show the info panel
    jQuery('.info-icon').hover(function() {
        jQuery(this).parent().find('#info-provider-panel').show();
    }, function() {
        jQuery(this).parent().find('#info-provider-panel').hide();
    });
    

    jQuery('#postcode-button').on('click', function() {
        let postcode = jQuery('.rebel-postcode').val();
        let button = jQuery('#postcode-button');
                button.find("p").hide(); // Hide the <p> element inside the button
                button.find("img").show()
        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "rebel_get_addresses", postcode: postcode},
            success: function(data) {
                data = data.toString();
                data = data.replace(/0+$/, '');
                var dataArray = JSON.parse(data);
   
                var select = jQuery('#rebel-addresses-select');
                select.css('display', 'block');
                jQuery('.rebel-postcode').css('width', '100%');
                jQuery(select).css('width', '100%');
                dataArray.forEach(function(address) {
                    var option = jQuery('<option></option>');
                option.text(address.fullAddress);
    
                option.attr('data-street', address.street);
                option.attr('data-posttown', address.posttown);
                option.attr('data-postcode', address.postcode);
                option.attr('data-number', address.number);
                option.attr('data-uprn', address.uprn);
                option.attr('data-mpan', address.mpan);
                option.attr('data-flat', address.flat);
                option.attr('data-buildingname', address.buildingname);
                option.attr('data-fullAddress', address.fullAddress);

                select.append(option); // Use jQuery's append method
                });
                button.find("p").show(); // Hide the <p> element inside the button
                button.find("img").hide()
                button.hide();
                jQuery('.rebel-postcode').css('max-width', '100%');
                jQuery('.rebel-postcode').css('width', '100%');
            },
        });
    });

    jQuery('.faq-question h4').click(function() {
        jQuery(this).parent().find('.faq-answer').slideToggle();
        // if .minus-img is hidden, show it else hide it and show .plus-img
        jQuery(this).parent().find('.minus-img').toggle();
        jQuery(this).parent().find('.plus-img').toggle();
      });

    jQuery('#rebel-addresses-select').on('change', function() {
        jQuery('#rebel-compare-now').css('display', 'flex');
    var selectedOption = jQuery('#rebel-addresses-select option:selected');
    var street = selectedOption.data('street'); // Retrieve data attribute values
    var posttown = selectedOption.data('posttown');
    var postcode = selectedOption.data('postcode');
    var number = selectedOption.data('number');
    var uprn = selectedOption.data('uprn');
    var mpan = selectedOption.data('mpan');
    var flat = selectedOption.data('flat');
    var buildingname = selectedOption.data('buildingname');
    var fullAddress = selectedOption.data('fulladdress');

    var queryString = window.location.search.substring(1);

    // Split the query string into an array of key-value pairs
    var queryParams = queryString.split("&");

    // Create an object to store the parameter-value pairs
    var params = {};
    queryParams.forEach(function(param) {
      var pair = param.split("=");
      if (pair.length === 2) {
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
        params[key] = value;
      }
    });

    var source = params["source"];
    var sid = params["sid"];
    var ssid = params["ssid"];
    var gclid = params["glid"];
    var msclkid = params["msclkid"];
    var affiliateID = params['affiliateID'];
    var transactionID = params['transactionID'];
    var clickref = params['click_ref'];
    
    // Build the query string with data attributes
    var queryString = 'fullAddress=' + encodeURIComponent(fullAddress) +
                      '&street=' + encodeURIComponent(street) +
                      '&posttown=' + encodeURIComponent(posttown) +
                      '&postcode=' + encodeURIComponent(postcode) +
                      '&number=' + encodeURIComponent(number) +
                      '&uprn=' + encodeURIComponent(uprn) +
                      '&mpan=' + encodeURIComponent(mpan) +
                      '&flat=' + encodeURIComponent(flat) +
                      '&buildingname=' + encodeURIComponent(buildingname) +
                      '&source=' + source +
                      '&sid=' + sid +
                      '&ssid=' + ssid +
                      '&gclid=' + gclid +
                      '&msclkid=' + msclkid +
                      '&affiliateID=' + affiliateID +
                      '&transactionID=' + transactionID +
                      '&click_ref=' + clickref;
    
    var newHref = '/energy/your-online-energy-quote/?' + queryString;
    
    jQuery('#rebel-compare-now a').attr('href', newHref);
    });

    jQuery('.choose-box').on('click', function() {
        var selectedBox = jQuery(this);
        jQuery('.choose-box').removeClass('checked');
        jQuery(selectedBox).addClass('checked');
        jQuery('.energy-checkbox div').removeClass('checked');
        jQuery(selectedBox).find('.energy-checkbox div').addClass('checked');
        jQuery('#reb-step1-btn').css('opacity', 1);
        jQuery("#reb-step1-btn").prop("disabled", false);
    });

    jQuery('.rebel-gen-quote-pre').on('click', function() {
        jQuery('.rebel-energy-options').hide();
        jQuery('.rebel-housesize').hide();
        //jQuery('.rebel-energy-consump').show();
    });

    // when .rebel-compare-loader is visible one by one display the li's of the ul
    jQuery('.rebel-compare-loader').each(function() {
        var $li = jQuery(this).find('li');
        var delay = 1000; // milliseconds
        $li.each(function(i) {
            jQuery(this).delay(delay * i).fadeIn(500);
        });
    });

    jQuery('#reb-step1-btn').on('click', function() {
    
        //jQuery('.rebel-energy-consump').hide();
        jQuery('.rebel-energy-options').hide();
        jQuery('.rebel-housesize').hide();
        jQuery('.rebel-compare-loader').show();
        if (jQuery('.select-consumption .active').data('level') == 'own') {
            var elecUsagepre = jQuery('#elec-consumption-value-pre').val();
            var gasUsagepre = jQuery('#gas-consumption-value-pre').val();
            jQuery('#elec-consumption-value-pre').val(elecUsagepre);
            jQuery('#gas-consumption-value-pre').val(gasUsagepre);
        }
        jQuery(window).scrollTop(0);

        var queryString = window.location.search.substring(1);
        jQuery('#optinurl').val(queryString);

        // Split the query string into an array of key-value pairs
        var queryParams = queryString.split("&");
  
        // Create an object to store the parameter-value pairs
        var params = {};
        queryParams.forEach(function(param) {
          var pair = param.split("=");
          if (pair.length === 2) {
            var key = decodeURIComponent(pair[0]);
            var value = decodeURIComponent(pair[1]);
            params[key] = value;
          }
        });

        var fullAddress = params["fullAddress"];
        var street = params["street"];
        var posttown = params["posttown"];
        var postcode = params["postcode"];
        var number = params["number"];
        var uprn = params["uprn"];
        var mpan = params["mpan"];
        var flat = params["flat"];
        var buildingname = params["buildingname"];
        var source = params["source"];
        var ssid = params["ssid"];
        var sid = params["sid"];
        var affiliateID = params["affiliateID"];
        var clickRef = params["click_ref"];

        jQuery('#mpanreg').val(mpan)
        jQuery('#uprnreg').val(uprn);
        jQuery('#fulladdressreg').val(fullAddress);
        jQuery('#numberreg').val(number);
        jQuery('#streetreg').val(street);
        jQuery('#posttownreg').val(posttown);
        jQuery('#postcodereg').val(postcode);
        jQuery('#source').val(source);
        jQuery('#ssid').val(ssid);
        jQuery('#sid').val(sid);
        jQuery('#rebel-affiliateID').val(affiliateID);
        jQuery('#rebel-click-ref').val(clickRef);

        var checkedDiv = jQuery(".rebel-energy-options .checked:first");
        var secondCheckedDiv = jQuery(".rebel-energy-options .checked:eq(1)");
        var houseType = secondCheckedDiv.data("value");
        var numberBedrooms = parseInt(jQuery('#nobedrooms').find('span').text());
        var supplyType = checkedDiv.data("value");
        var affiliateID = jQuery('#rebel-affiliateID').val();

        if (houseType == 'house' || houseType == 'flat') {
            var useHouseConsumption = true;
        }

        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "generate_rebel_quote", fullAddress: fullAddress, street: street, posttown: posttown, postcode: postcode, number: number, uprn: uprn, mpan: mpan, flat: flat, buildingname: buildingname, supplyType: supplyType, houseType: houseType, numberBedrooms: numberBedrooms, useHouseConsumption: useHouseConsumption},
            success: function(data) {
                console.log(data);
                jQuery('.rebel-compare-loader').hide();
                jQuery(window).scrollTop(0);
                    // Check if the data contains the specified string
                    if (data.includes("Internal server error")) {
      
                       jQuery('.rebel-housesize').show();
                    }
                    if (data.includes("Non domestic customer") || data.includes("undefined cannot be resolved")) {
                        jQuery('.non-domestic').show();
                    }
                    if (data.includes("Provide household size")) {
                        jQuery('.rebel-housesize').show();
                    } else {
                       data = data.toString();
                       data = data.replace(/0+$/, '');
                       var jsonData = JSON.parse(data);
                       var tariffs = jsonData.tariffs;
                       // loop tariffs and set fixed to one with tariffID of 238
                          for (var i = 0; i < tariffs.length; i++) {
                                if (tariffs[i].tariffId == 246) {
                                    var fixedTariff = tariffs[i];
                                }
                            }
                        // DEV
                        //var fixedTariff = jsonData.tariffs[0];

                        var quoteBasis = jsonData.quoteBasis; 
        

                        var supplyType = quoteBasis.supplyType;


                        if (supplyType == 'both') {
                            supplyType = "Gas and electric";
                            var mprn = quoteBasis.gas.mprn;
                            jQuery('#mprnreg').val(mprn);  
                        }
                        if (supplyType == 'electric') {
                            supplyType = "Electric";
                            // if quoteBasis.gas.mprn exists then it is both
                            if (quoteBasis.gas) {
                                supplyType = "Gas and electric";
                                var mprn = quoteBasis.gas.mprn;
                                jQuery('#mprnreg').val(mprn);  
                            } 
                        }
                        if (supplyType == 'gas') {
                            supplyType = 'Gas';
                            var mprn = quoteBasis.gas.mprn;
                            jQuery('#mprnreg').val(mprn);  
                        }

                        var dualFuel = "dualFuel";
                        var elecOnly = "electricityOnly"; 
                        
                        if (supplyType == 'Gas and electric') {
                            jQuery('#fuelTypereg').val(dualFuel);
                            jQuery('#fuelTypeRev').val('120');
                        } else {
                            jQuery('#fuelTypereg').val(elecOnly);
                            jQuery('#fuelTypeRev').val('60');
                        }

                        jQuery('#summary-supplytype').text(supplyType);

                        var elecUsage = quoteBasis.electric.calculationData.consumption;
                        jQuery('#elec-consumption-value').show();
                        if (supplyType == "Gas and electric") {
                        var gasusage = quoteBasis.gas.calculationData.consumption;
                        jQuery('#gas-consumption-value').show();
                        } else {
                            jQuery('.your-consumption > div:eq(1)').hide();
                        }

                        jQuery('#elecUsagereg').val(elecUsage);
                        jQuery('#elec-consumption-value').val(elecUsage);
                        jQuery('#gasUsagereg').val(gasusage);
                        jQuery('#gas-consumption-value').val(gasusage);

                        jQuery('.estimated-consumption #estimate-elec').text(elecUsage + " kWh per year");
                        jQuery('.estimated-consumption #estimate-gas').text(gasusage + " kWh per year");


                        var profileClass = quoteBasis.profileClasses[0];
                        jQuery('#profileClassreg').val(profileClass);

                        var fixedElecPaymentAmount = fixedTariff.calculated[0].monthlyAmountInPounds;
                        if (supplyType == "Gas and electric") {
                        var fixedGasPaymentAmount = fixedTariff.calculated[1].monthlyAmountInPounds;
                        }
                        jQuery('#fixedamountElecreg').val(fixedElecPaymentAmount);
                        jQuery('#fixedamountGasreg').val(fixedGasPaymentAmount);

                        var fixedTariffID = fixedTariff.tariffId;
                        jQuery('#fixedTariffIDreg').val(fixedTariffID);
                        var fixedTariffExitFee = fixedTariff.exitFee;
                        var fixedTariffTotalAnnually = fixedTariff.totalAnnually;
                        fixedTariffTotalAnnually = parseFloat(fixedTariffTotalAnnually.toFixed(2));
                        var fixedTariffTotalMonthly = fixedTariff.totalMonthly;
                        fixedTariffTotalMonthly = parseFloat(fixedTariffTotalMonthly.toFixed(2));

                        var fixedTariffElectricCalc = fixedTariff.calculated[0];
                        var fixedTariffElectricCalcConsumption = fixedTariffElectricCalc.consumptionQuoted;
                        var fixedTariffElectricCalcConsumptionEstimate = fixedTariffElectricCalc.isEstimateConsumption;
                        var fixedTariffElectricRate = fixedTariffElectricCalc.ratesIncVat.unitRateInPence;
                        if (fixedTariffElectricRate == undefined) {
                            fixedTariffElectricRate = fixedTariffElectricCalc.ratesIncVat.dayUnitRateInPence;
                            var nightfixedTariffElectricRate = fixedTariffElectricCalc.ratesIncVat.nightUnitRateInPence;
                            nightfixedTariffElectricRate = parseFloat(nightfixedTariffElectricRate.toFixed(2));
                        }
                        fixedTariffElectricRate = parseFloat(fixedTariffElectricRate.toFixed(2));
                        var fixedTariffElectricStanding = fixedTariffElectricCalc.ratesIncVat.standingChargeInPence;
                        fixedTariffElectricStanding = parseFloat(fixedTariffElectricStanding.toFixed(2));

                        if (supplyType == "Gas and electric") {
                        var fixedTariffGasCalc = fixedTariff.calculated[1];
                        var fixedTariffGasCalcConsumption = fixedTariffGasCalc.consumptionQuoted;
                        var fixedTariffGasCalcConsumptionEstimate = fixedTariffGasCalc.isEstimateConsumption;
                        var fixedTariffGasRate = fixedTariffGasCalc.ratesIncVat.unitRateInPence;
                        fixedTariffGasRate = parseFloat(fixedTariffGasRate.toFixed(2));
                        var fixedTariffGasStanding = fixedTariffGasCalc.ratesIncVat.standingChargeInPence;
                        fixedTariffGasStanding = parseFloat(fixedTariffGasStanding.toFixed(2));
                        }

                        var dateWithoutCoolOff = jsonData.datesIfCoolOffWaived;
                        var datesIfCoolOffWaivedPaymentDate = dateWithoutCoolOff.paymentStartDayOfMonth;
                        var datesIfCoolOffWaivedStartPaymentDate = dateWithoutCoolOff.paymentStartDate;
                        var datesIfCoolOffWaivedStartContractDate = dateWithoutCoolOff.contractStartDate;
                        var datesIfCoolOffWaivedEndContractDate = dateWithoutCoolOff.contractEndDate;

                        var dateWithCoolOff = jsonData.	datesIfCoolOffNotWaived;
                        var datesIfCoolOffNotWaivedPaymentDate = dateWithCoolOff.paymentStartDayOfMonth;
                        var datesIfCoolOffNotWaivedStartPaymentDate = dateWithCoolOff.paymentStartDate;
                        var datesIfCoolOffNotWaivedStartContractDate = dateWithCoolOff.contractStartDate;
                        var datesIfCoolOffNotWaivedEndContractDate = dateWithCoolOff.contractEndDate;

                        // convert datesIfCoolOffWaivedStartContractDate in readable date in format 9th January 2024
                        var datesIfCoolOffWaivedStartContractDate = new Date(datesIfCoolOffWaivedStartContractDate);
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.toDateString();
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.split(' ');
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate[1] + ' ' + datesIfCoolOffWaivedStartContractDate[2] + ' ' + datesIfCoolOffWaivedStartContractDate[3];
                        // format date to 9th January 2024
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.split(' ');
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate[1] + ' ' + datesIfCoolOffWaivedStartContractDate[0] + ' ' + datesIfCoolOffWaivedStartContractDate[2];
                        // add suffix to date
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.split(' ');
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate[0] + getDaySuffix(datesIfCoolOffWaivedStartContractDate[0]) + ' ' + datesIfCoolOffWaivedStartContractDate[1] + ' ' + datesIfCoolOffWaivedStartContractDate[2];

                        // if first number is 0 remove
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.split(' ');
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate[0].replace(/^0+/, '') + ' ' + datesIfCoolOffWaivedStartContractDate[1] + ' ' + datesIfCoolOffWaivedStartContractDate[2];
                        
                        // do the same for datesIfCoolOffNotWaivedStartContractDate
                        var datesIfCoolOffNotWaivedStartContractDate = new Date(datesIfCoolOffNotWaivedStartContractDate);
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate.toDateString();
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate.split(' ');
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate[1] + ' ' + datesIfCoolOffNotWaivedStartContractDate[2] + ' ' + datesIfCoolOffNotWaivedStartContractDate[3];
                        // format date to 9th January 2024
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate.split(' ');
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate[1] + ' ' + datesIfCoolOffNotWaivedStartContractDate[0] + ' ' + datesIfCoolOffNotWaivedStartContractDate[2];
                        // add suffix to date
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate.split(' ');
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate[0] + getDaySuffix(datesIfCoolOffNotWaivedStartContractDate[0]) + ' ' + datesIfCoolOffNotWaivedStartContractDate[1] + ' ' + datesIfCoolOffNotWaivedStartContractDate[2];

                        // do the same for datesIfCoolOffWaivedStartPaymentDate
                        var datesIfCoolOffWaivedStartPaymentDate = new Date(datesIfCoolOffWaivedStartPaymentDate);
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate.toDateString();
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffWaivedStartPaymentDate[2] + ' ' + datesIfCoolOffWaivedStartPaymentDate[3];
                        // format date to 9th January 2024
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffWaivedStartPaymentDate[0] + ' ' + datesIfCoolOffWaivedStartPaymentDate[2];
                        // add suffix to date
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate[0] + getDaySuffix(datesIfCoolOffWaivedStartPaymentDate[0]) + ' ' + datesIfCoolOffWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffWaivedStartPaymentDate[2];

                        // do the same for datesIfCoolOffNotWaivedStartPaymentDate
                        var datesIfCoolOffNotWaivedStartPaymentDate = new Date(datesIfCoolOffNotWaivedStartPaymentDate);
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate.toDateString();
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[2] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[3];
                        // format date to 9th January 2024
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[0] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[2];
                        // add suffix to date
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate[0] + getDaySuffix(datesIfCoolOffNotWaivedStartPaymentDate[0]) + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[2];

                    

                        jQuery('.cooloffblock .col:eq(0) h2').text(datesIfCoolOffWaivedStartContractDate);

                        jQuery('.cooloffblock .col:eq(1) h2').text(datesIfCoolOffNotWaivedStartContractDate);

                        jQuery('#tariff-details-panel p:eq(1)').text(supplyType);
                        jQuery('.fixed-tariff .rebel-price h2').text('£' + fixedTariffTotalMonthly);
                        jQuery('.details-overview p:nth-child(1) span').text('£' + fixedTariffTotalMonthly);
                        jQuery('.annual-price p').text('Based on your estimated consumption at an annual rate of £' + fixedTariffTotalAnnually + ' (Incl. VAT)');
                        jQuery('.wrapper .quote-quotes h4').text('£' + fixedTariffTotalMonthly + ' per month ' + '(Incl. VAT)');
                        jQuery('.wrapper .quote-quotes p').text('Based on your estimated consumption at an annual rate of £' + fixedTariffTotalAnnually + ' (Incl. VAT)');
                        //jQuery('.fixed-tariff > .tariff-content > .tariff-details > .tariff-details-panel div:eq(1)').text(fixedTariffName);
                        jQuery('#tariff-details-panel p:eq(4)').text('£' + fixedTariffExitFee + ' per fuel');
                        jQuery('#tariff-details-panel p:eq(5)').text(fixedTariffElectricRate + 'p per kWh');
                        jQuery('#tariff-details-panel p:eq(6)').text(fixedTariffElectricStanding + 'p per day');
                        jQuery('#tariff-details-panel p:eq(7)').text(fixedTariffGasRate + 'p per kWh');
                        jQuery('#tariff-details-panel p:eq(8)').text(fixedTariffGasStanding + 'p per day');

                        jQuery('.quote-sum > div > p:eq(0)').text();
    
                        jQuery('.rebel-got-quote').show();
                        //jQuery('#update-quote').click();


                        jQuery('#skip-cooloff a').on('click', function() {
                            jQuery('.final-section').show();
                            jQuery('#rebelskipcooloff').val('Y');
                            jQuery('#overview-date').text(datesIfCoolOffWaivedStartContractDate);
                            jQuery('.details-overview p:nth-child(2) span').text(datesIfCoolOffWaivedStartPaymentDate);
                            jQuery('.details-overview #cooloff-info').text('You have waived your 14 day cooling off period. So you will not be able to cancel without fees within the first 14 days.');
                            jQuery('.tariff-usp #cool-off-period span').text('Start your supply faster');
                            jQuery('#summary-cooloff').text('No cooling off period, your supply starts sooner.');
                            jQuery('.top-personal-bg').hide();
                            jQuery('html, body').animate({
                                scrollTop: jQuery('body').offset().top 
                            }, 'slow');
                        });
                    
                        jQuery('#noskip-cooloff a').on('click', function() {
                            jQuery('.final-section').show();
                            jQuery('#rebelskipcooloff').val('N');
                            jQuery('#overview-date').text(datesIfCoolOffNotWaivedStartContractDate);
                            jQuery('.details-overview p:nth-child(2) span').text(datesIfCoolOffNotWaivedStartPaymentDate);
                            jQuery('.details-overview #cooloff-info').text('You can cancel anytime within the first 14 days.');
                            jQuery('#summary-cooloff').text('You can cancel within 14 days');
                            jQuery('.top-personal-bg').hide();
                            // scroll to top of page
                            jQuery('html, body').animate({
                                scrollTop: jQuery('body').offset().top
                            }, 'slow');
                        });

                        jQuery('#summary-address').text(fullAddress);

                        jQuery('#quote-cta-btn').on('click', function() {

                            jQuery('.rebel-got-quote').hide();
                            jQuery('.rebel-personal-details').show();
                            jQuery(window).scrollTop(0);

                        });

                        if (elecUsage <= 2699 && elecUsage >= 1800) {
                            jQuery('.select-consumption div[data-level="low"]').click();
                        }

                        if (elecUsage >= 2700 && elecUsage <= 4099) {
                            jQuery('.select-consumption div[data-level="medium"]').click();
                        }
                        if (elecUsage >= 4100) {
                            jQuery('.select-consumption div[data-level="high"]').click();
                        }


                    }
                    
            },
        });

    });


    jQuery('.rebel-gen-quote').on('click', function() {
    
        //jQuery('.rebel-energy-consump').hide();
        jQuery('.rebel-energy-options').hide();
        jQuery('.rebel-housesize').hide();
        jQuery('.rebel-compare-loader').show();
        if (jQuery('.select-consumption .active').data('level') == 'own') {
            var elecUsagepre = jQuery('#elec-consumption-value-pre').val();
            var gasUsagepre = jQuery('#gas-consumption-value-pre').val();
            jQuery('#elec-consumption-value-pre').val(elecUsagepre);
            jQuery('#gas-consumption-value-pre').val(gasUsagepre);
        }
        jQuery(window).scrollTop(0);

        var queryString = window.location.search.substring(1);

        // Split the query string into an array of key-value pairs
        var queryParams = queryString.split("&");
  
        // Create an object to store the parameter-value pairs
        var params = {};
        queryParams.forEach(function(param) {
          var pair = param.split("=");
          if (pair.length === 2) {
            var key = decodeURIComponent(pair[0]);
            var value = decodeURIComponent(pair[1]);
            params[key] = value;
          }
        });

        var fullAddress = params["fullAddress"];
        var street = params["street"];
        var posttown = params["posttown"];
        var postcode = params["postcode"];
        var number = params["number"];
        var uprn = params["uprn"];
        var mpan = params["mpan"];
        var flat = params["flat"];
        var buildingname = params["buildingname"];
        var source = params["source"];
        var ssid = params["ssid"];
        var sid = params["sid"];
        var affiliateID = params["affiliateID"];
        var clickRef = params["click_ref"];

        jQuery('#mpanreg').val(mpan)
        jQuery('#uprnreg').val(uprn);
        jQuery('#fulladdressreg').val(fullAddress);
        jQuery('#numberreg').val(number);
        jQuery('#streetreg').val(street);
        jQuery('#posttownreg').val(posttown);
        jQuery('#postcodereg').val(postcode);
        jQuery('#source').val(source);
        jQuery('#ssid').val(ssid);
        jQuery('#sid').val(sid);
        jQuery('#rebel-affiliateID').val(affiliateID);
        jQuery('#rebel-click-ref').val(clickRef);

        var checkedDiv = jQuery(".rebel-energy-options .checked:first");
        var secondCheckedDiv = jQuery(".rebel-energy-options .checked:eq(1)");
        var houseType = secondCheckedDiv.data("value");
        var numberBedrooms = parseInt(jQuery('#nobedrooms').find('span').text());
        var supplyType = checkedDiv.data("value");
        var affiliateID = jQuery('#rebel-affiliateID').val();

        if (houseType == 'house' || houseType == 'flat') {
            var useHouseConsumption = true;
        }

        jQuery.ajax({
            url: ajax_object.ajaxurl,
            type: "post",
            data: {action: "generate_rebel_quote", fullAddress: fullAddress, street: street, posttown: posttown, postcode: postcode, number: number, uprn: uprn, mpan: mpan, flat: flat, buildingname: buildingname, supplyType: supplyType, houseType: houseType, numberBedrooms: numberBedrooms, useHouseConsumption: useHouseConsumption},
            success: function(data) {
                console.log(data);
                jQuery('.rebel-compare-loader').hide();
                jQuery(window).scrollTop(0);
                    // Check if the data contains the specified string
                    if (data.includes("Internal server error")) {
            
                       jQuery('.rebel-housesize').show();
                    }
                    if (data.includes("Non domestic customer") || data.includes("undefined cannot be resolved")) {
                        jQuery('.non-domestic').show();
                    }
                    if (data.includes("Provide household size")) {
                        jQuery('.rebel-housesize').show();
                    } else {
           
                       data = data.toString();
                       data = data.replace(/0+$/, '');
                       var jsonData = JSON.parse(data);
                       var tariffs = jsonData.tariffs;
                       // loop tariffs and set fixed to one with tariffID of 238
                          for (var i = 0; i < tariffs.length; i++) {
                                if (tariffs[i].tariffId == 246) {
                                    var fixedTariff = tariffs[i];
                                }
                            }

                        // DEV
                        //var fixedTariff = jsonData.tariffs[0];

                        var quoteBasis = jsonData.quoteBasis;
        

                        var supplyType = quoteBasis.supplyType;

                        if (supplyType == 'both') {
                            supplyType = "Gas and electric";
                            var mprn = quoteBasis.gas.mprn;
                            jQuery('#mprnreg').val(mprn);  
                        }
                        if (supplyType == 'electric') {
                            supplyType = "Electric";
            
                        }
                        if (supplyType == 'gas') {
                            supplyType = 'Gas';
                            var mprn = quoteBasis.gas.mprn;
                            jQuery('#mprnreg').val(mprn);  
                        }

                        var dualFuel = "dualFuel";
                        var elecOnly = "electricityOnly"; 
                        
                        if (supplyType == 'Gas and electric') {
                            jQuery('#fuelTypereg').val(dualFuel);
                            jQuery('#fuelTypeRev').val('120');
                        } else {
                            jQuery('#fuelTypereg').val(elecOnly);
                            jQuery('#fuelTypeRev').val('60');
                        }

                        jQuery('#summary-supplytype').text(supplyType);

                        var elecUsage = quoteBasis.electric.calculationData.consumption;
                        jQuery('#elec-consumption-value').show();
                        if (supplyType == "Gas and electric") {
                        var gasusage = quoteBasis.gas.calculationData.consumption;
                        jQuery('#gas-consumption-value').show();
                        } else {
                            jQuery('.your-consumption > div:eq(1)').hide();
                        }

                        jQuery('#elecUsagereg').val(elecUsage);
                        jQuery('#elec-consumption-value').val(elecUsage);
                        jQuery('#gasUsagereg').val(gasusage);
                        jQuery('#gas-consumption-value').val(gasusage);

                        jQuery('.estimated-consumption #estimate-elec').text(elecUsage + " kWh per year");
                        jQuery('.estimated-consumption #estimate-gas').text(gasusage + " kWh per year");


                        var profileClass = quoteBasis.profileClasses[0];
                        jQuery('#profileClassreg').val(profileClass);

                        var fixedElecPaymentAmount = fixedTariff.calculated[0].monthlyAmountInPounds;
                        if (supplyType == "Gas and electric") {
                        var fixedGasPaymentAmount = fixedTariff.calculated[1].monthlyAmountInPounds;
                        }
                        jQuery('#fixedamountElecreg').val(fixedElecPaymentAmount);
                        jQuery('#fixedamountGasreg').val(fixedGasPaymentAmount);

                        var fixedTariffID = fixedTariff.tariffId;
                        jQuery('#fixedTariffIDreg').val(fixedTariffID);
                        var fixedTariffExitFee = fixedTariff.exitFee;
                        var fixedTariffTotalAnnually = fixedTariff.totalAnnually;
                        fixedTariffTotalAnnually = parseFloat(fixedTariffTotalAnnually.toFixed(2));
                        var fixedTariffTotalMonthly = fixedTariff.totalMonthly;
                        fixedTariffTotalMonthly = parseFloat(fixedTariffTotalMonthly.toFixed(2));

                        var fixedTariffElectricCalc = fixedTariff.calculated[0];
                        var fixedTariffElectricCalcConsumption = fixedTariffElectricCalc.consumptionQuoted;
                        var fixedTariffElectricCalcConsumptionEstimate = fixedTariffElectricCalc.isEstimateConsumption;
                        var fixedTariffElectricRate = fixedTariffElectricCalc.ratesIncVat.unitRateInPence;
                        if (typeof fixedTariffElectricRate === 'undefined') {
                            fixedTariffElectricRate = fixedTariffElectricCalc.ratesIncVat.dayUnitRateInPence;
                            var dayfixedTariffElectricRate = fixedTariffElectricCalc.ratesIncVat.dayUnitRateInPence;
                            var nightfixedTariffElectricRate = fixedTariffElectricCalc.ratesIncVat.nightUnitRateInPence;
                            //nightfixedTariffElectricRate = parseFloat(nightfixedTariffElectricRate.toFixed(2));

                            // create fixed rate for day and night combined with a 58% day and 42% night split
                            var dayRate = dayfixedTariffElectricRate * 0.58;
                            var nightRate = nightfixedTariffElectricRate * 0.42;
                            var combinedRate = dayRate + nightRate;
                            fixedTariffElectricRate = combinedRate;
                        }
                        fixedTariffElectricRate = parseFloat(fixedTariffElectricRate.toFixed(2));
                        var fixedTariffElectricStanding = fixedTariffElectricCalc.ratesIncVat.standingChargeInPence;
                        fixedTariffElectricStanding = parseFloat(fixedTariffElectricStanding.toFixed(2));

                        if (supplyType == "Gas and electric") {
                        var fixedTariffGasCalc = fixedTariff.calculated[1];
                        var fixedTariffGasCalcConsumption = fixedTariffGasCalc.consumptionQuoted;
                        var fixedTariffGasCalcConsumptionEstimate = fixedTariffGasCalc.isEstimateConsumption;
                        var fixedTariffGasRate = fixedTariffGasCalc.ratesIncVat.unitRateInPence;
                        fixedTariffGasRate = parseFloat(fixedTariffGasRate.toFixed(2));
                        var fixedTariffGasStanding = fixedTariffGasCalc.ratesIncVat.standingChargeInPence;
                        fixedTariffGasStanding = parseFloat(fixedTariffGasStanding.toFixed(2));
                        }

                        var dateWithoutCoolOff = jsonData.datesIfCoolOffWaived;
                        var datesIfCoolOffWaivedPaymentDate = dateWithoutCoolOff.paymentStartDayOfMonth;
                        var datesIfCoolOffWaivedStartPaymentDate = dateWithoutCoolOff.paymentStartDate;
                        var datesIfCoolOffWaivedStartContractDate = dateWithoutCoolOff.contractStartDate;
                        var datesIfCoolOffWaivedEndContractDate = dateWithoutCoolOff.contractEndDate;

                        var dateWithCoolOff = jsonData.	datesIfCoolOffNotWaived;
                        var datesIfCoolOffNotWaivedPaymentDate = dateWithCoolOff.paymentStartDayOfMonth;
                        var datesIfCoolOffNotWaivedStartPaymentDate = dateWithCoolOff.paymentStartDate;
                        var datesIfCoolOffNotWaivedStartContractDate = dateWithCoolOff.contractStartDate;
                        var datesIfCoolOffNotWaivedEndContractDate = dateWithCoolOff.contractEndDate;

                        // convert datesIfCoolOffWaivedStartContractDate in readable date in format 9th January 2024
                        var datesIfCoolOffWaivedStartContractDate = new Date(datesIfCoolOffWaivedStartContractDate);
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.toDateString();
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.split(' ');
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate[1] + ' ' + datesIfCoolOffWaivedStartContractDate[2] + ' ' + datesIfCoolOffWaivedStartContractDate[3];
                        // format date to 9th January 2024
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.split(' ');
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate[1] + ' ' + datesIfCoolOffWaivedStartContractDate[0] + ' ' + datesIfCoolOffWaivedStartContractDate[2];
                        // add suffix to date
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.split(' ');
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate[0] + getDaySuffix(datesIfCoolOffWaivedStartContractDate[0]) + ' ' + datesIfCoolOffWaivedStartContractDate[1] + ' ' + datesIfCoolOffWaivedStartContractDate[2];

                        // if first number is 0 remove
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate.split(' ');
                        var datesIfCoolOffWaivedStartContractDate = datesIfCoolOffWaivedStartContractDate[0].replace(/^0+/, '') + ' ' + datesIfCoolOffWaivedStartContractDate[1] + ' ' + datesIfCoolOffWaivedStartContractDate[2];
                        
                        // do the same for datesIfCoolOffNotWaivedStartContractDate
                        var datesIfCoolOffNotWaivedStartContractDate = new Date(datesIfCoolOffNotWaivedStartContractDate);
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate.toDateString();
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate.split(' ');
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate[1] + ' ' + datesIfCoolOffNotWaivedStartContractDate[2] + ' ' + datesIfCoolOffNotWaivedStartContractDate[3];
                        // format date to 9th January 2024
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate.split(' ');
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate[1] + ' ' + datesIfCoolOffNotWaivedStartContractDate[0] + ' ' + datesIfCoolOffNotWaivedStartContractDate[2];
                        // add suffix to date
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate.split(' ');
                        var datesIfCoolOffNotWaivedStartContractDate = datesIfCoolOffNotWaivedStartContractDate[0] + getDaySuffix(datesIfCoolOffNotWaivedStartContractDate[0]) + ' ' + datesIfCoolOffNotWaivedStartContractDate[1] + ' ' + datesIfCoolOffNotWaivedStartContractDate[2];

                        // do the same for datesIfCoolOffWaivedStartPaymentDate
                        var datesIfCoolOffWaivedStartPaymentDate = new Date(datesIfCoolOffWaivedStartPaymentDate);
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate.toDateString();
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffWaivedStartPaymentDate[2] + ' ' + datesIfCoolOffWaivedStartPaymentDate[3];
                        // format date to 9th January 2024
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffWaivedStartPaymentDate[0] + ' ' + datesIfCoolOffWaivedStartPaymentDate[2];
                        // add suffix to date
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffWaivedStartPaymentDate = datesIfCoolOffWaivedStartPaymentDate[0] + getDaySuffix(datesIfCoolOffWaivedStartPaymentDate[0]) + ' ' + datesIfCoolOffWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffWaivedStartPaymentDate[2];

                        // do the same for datesIfCoolOffNotWaivedStartPaymentDate
                        var datesIfCoolOffNotWaivedStartPaymentDate = new Date(datesIfCoolOffNotWaivedStartPaymentDate);
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate.toDateString();
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[2] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[3];
                        // format date to 9th January 2024
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[0] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[2];
                        // add suffix to date
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate.split(' ');
                        var datesIfCoolOffNotWaivedStartPaymentDate = datesIfCoolOffNotWaivedStartPaymentDate[0] + getDaySuffix(datesIfCoolOffNotWaivedStartPaymentDate[0]) + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[1] + ' ' + datesIfCoolOffNotWaivedStartPaymentDate[2];

                    

                        jQuery('.cooloffblock .col:eq(0) h2').text(datesIfCoolOffWaivedStartContractDate);

                        jQuery('.cooloffblock .col:eq(1) h2').text(datesIfCoolOffNotWaivedStartContractDate);

                        jQuery('#tariff-details-panel p:eq(1)').text(supplyType);
                        jQuery('.fixed-tariff .rebel-price h2').text('£' + fixedTariffTotalMonthly);
                        jQuery('.details-overview p:nth-child(1) span').text('£' + fixedTariffTotalMonthly);
                        jQuery('.annual-price p').text('Based on your estimated consumption at an annual rate of £' + fixedTariffTotalAnnually + ' (Incl. VAT)');
                        jQuery('.wrapper .quote-quotes h4').text('£' + fixedTariffTotalMonthly + ' per month ' + '(Incl. VAT)');
                        jQuery('.wrapper .quote-quotes p').text('Based on your estimated consumption at an annual rate of £' + fixedTariffTotalAnnually + ' (Incl. VAT)');
                        //jQuery('.fixed-tariff > .tariff-content > .tariff-details > .tariff-details-panel div:eq(1)').text(fixedTariffName);
                        jQuery('#tariff-details-panel p:eq(4)').text('£' + fixedTariffExitFee + ' per fuel');
                        jQuery('#tariff-details-panel p:eq(5)').text(fixedTariffElectricRate + 'p per kWh');
                        jQuery('#tariff-details-panel p:eq(6)').text(fixedTariffElectricStanding + 'p per day');
                        jQuery('#tariff-details-panel p:eq(7)').text(fixedTariffGasRate + 'p per kWh');
                        jQuery('#tariff-details-panel p:eq(8)').text(fixedTariffGasStanding + 'p per day');

                        jQuery('.quote-sum > div > p:eq(0)').text();
    
                        jQuery('.rebel-got-quote').show();
                        //jQuery('#update-quote').click();


                        jQuery('#skip-cooloff a').on('click', function() {
                            jQuery('.final-section').show();
                            jQuery('#rebelskipcooloff').val('Y');
                            jQuery('#overview-date').text(datesIfCoolOffWaivedStartContractDate);
                            jQuery('.details-overview p:nth-child(2) span').text(datesIfCoolOffWaivedStartPaymentDate);
                            jQuery('.details-overview #cooloff-info').text('You have waived your 14 day cooling off period. So you will not be able to cancel without fees within the first 14 days.');
                            jQuery('.tariff-usp #cool-off-period span').text('Start your supply faster');
                            jQuery('#summary-cooloff').text('No cooling off period, your supply starts sooner.');
                            jQuery('.top-personal-bg').hide();
                            jQuery('html, body').animate({
                                scrollTop: jQuery('body').offset().top 
                            }, 'slow');
                        });
                    
                        jQuery('#noskip-cooloff a').on('click', function() {
                            jQuery('.final-section').show();
                            jQuery('#rebelskipcooloff').val('N');
                            jQuery('#overview-date').text(datesIfCoolOffNotWaivedStartContractDate);
                            jQuery('.details-overview p:nth-child(2) span').text(datesIfCoolOffNotWaivedStartPaymentDate);
                            jQuery('.details-overview #cooloff-info').text('You can cancel anytime within the first 14 days.');
                            jQuery('#summary-cooloff').text('You can cancel within 14 days');
                            jQuery('.top-personal-bg').hide();
                            // scroll to top of page
                            jQuery('html, body').animate({
                                scrollTop: jQuery('body').offset().top
                            }, 'slow');
                        });

                        jQuery('#summary-address').text(fullAddress);

                        jQuery('#quote-cta-btn').on('click', function() {

                            jQuery('.rebel-got-quote').hide();
                            jQuery('.rebel-personal-details').show();
                            jQuery(window).scrollTop(0);

                        });

                        if (elecUsage <= 2699 && elecUsage >= 1800) {
                            jQuery('.select-consumption div[data-level="low"]').click();
                        }

                        if (elecUsage >= 2700 && elecUsage <= 4099) {
                            jQuery('.select-consumption div[data-level="medium"]').click();
                        }
                        if (elecUsage >= 4100) {
                            jQuery('.select-consumption div[data-level="high"]').click();
                        }


                    }
                    
            },
        });

    });

    jQuery('#join-rebel-btn').on('click', function(event) {
        jQuery('#join-rebel-btn .fas').show();
        var title = jQuery('#titleSelect').val();
        jQuery('#titlereg').val(title);
        var firstName = jQuery('input[name="firstname"]').val();
        jQuery('#firstnamereg').val(firstName);
        var lastname = jQuery('input[name="lastname"]').val();
        jQuery('#lastnamereg').val(lastname);
        var email = jQuery('input[name="email"]').val();
        jQuery('#emailreg').val(email);
        var phone = jQuery('input[name="phone"]').val();
        jQuery('#phonereg').val(phone);
        

        var isFormValid = true;
        jQuery('.rebel-personal-form > form').find('input').each(function() {
            if (jQuery(this).val() === '') {
                jQuery(this).next('.field-error').show();
                jQuery('html, body').animate({
                    scrollTop: jQuery('.field-error').offset().top
                }, 'slow');
                isFormValid = false;
                return false; 
            } else {
                jQuery(this).next('.field-error').hide();
            }
        });
        if (jQuery('#titleSelect').val() == null) {
            jQuery('#titleSelect').next('.field-error').show();
            jQuery('html, body').animate({
                scrollTop: jQuery('.field-error').offset().top
            }, 'slow');
            isFormValid = false;
            return false; 
        } else {
            jQuery(this).next('.field-error').hide();
        }
        

        var accountHolder = jQuery("input[name='accountholder']").val();
        if (accountHolder === "") {
            jQuery("input[name='accountholder']").after("<p class='error'>Please enter the name as it appears on the account.</p>");
            isFormValid = false;
        }

        // Validate Account Number
        var accountNumber = jQuery("input[name='accountnumber']").val();
        if (!/^\d{6,8}$/.test(accountNumber)) {
            jQuery("input[name='accountnumber']").after("<p class='error'>Account Number must be 6 to 8 digits long. eg, 76811027</p>");
            isFormValid = false;
        }

        // Validate Sort code
        var sortCode = jQuery("input[name='sortcode']").val();
        // remove - from sort code
        sortCode = sortCode.replace(/-/g, '');
        if (!/^\d{6}$/.test(sortCode)) {
            jQuery("input[name='sortcode']").after("<p class='error'>Sort Code must be 6 digits long. eg, 601611</p>");
            isFormValid = false;
        }

        if (!isFormValid) {
            event.preventDefault(); // Prevent form submission
    
        } else {

            jQuery(".error").remove();
            
            var accountHolder = jQuery('input[name="accountholder"]').val();
            var accountnumber = jQuery('input[name="accountnumber"]').val();
            var sortcode = jQuery('input[name="sortcode"]').val();

            var title = jQuery('#titlereg').val();
            var firstname = jQuery('#firstnamereg').val();
            var lastname = jQuery('#lastnamereg').val();
            var email = jQuery('#emailreg').val();
            var phone = jQuery('#phonereg').val();

            var mpan = jQuery('#mpanreg').val();
            var uprn = jQuery('#uprnreg').val();
            var mprn = jQuery('#mprnreg').val();

            var elecUsage = parseInt(jQuery('#elecUsagereg').val());
            var gasUsage = parseInt(jQuery('#gasUsagereg').val());

            var fuelType = jQuery('#fuelTypereg').val();
            var tariffType = jQuery('#tariffTypereg').val();

                var elecPaymentAmount = jQuery('#fixedamountElecreg').val();
                var gasPaymentAmount = jQuery('#fixedamountGasreg').val();
                var productCode = jQuery('#fixedTariffIDreg').val();
                

            var profileClass = jQuery('input[name="profileClass-reg"]').val();

            var fullAddress = jQuery('input[name="fullAddress-reg"]').val();
            var number = jQuery('input[name="number-reg"]').val();
            var street = jQuery('input[name="street-reg"]').val();
            var flat = jQuery('input[name="flat-reg"]').val();
            var posttown = jQuery('input[name="posttown-reg"]').val();
            var postcode = jQuery('input[name="postcode-reg"]').val();
            var gclid = jQuery('#gclid').val();
            var msclkid = jQuery('#msclkid').val();

            var skipcooloff = jQuery('#rebelskipcooloff').val();

            var externalRef = generateRandomString(16);
            jQuery('#externalref').val(externalRef);
            jQuery('.join-error').hide();
            
            var clickRef = jQuery('#click_ref').val();
            var rebel_fuels = jQuery('#fuelTypereg').val();
            var affiliateID = jQuery('#rebel-affiliateID').val();

           // get rebel cookie from browser

            var rebelAff = document.cookie.match('(^|;)\\s*' + 'rebel' + '\\s*=\\s*([^;]+)')?.pop() || '';
            // if cookie is set get value
            if (rebelAff != '') {
                var rebelAff = document.cookie.match('(^|;)\\s*' + 'rebel' + '\\s*=\\s*([^;]+)')?.pop() || '';
                // split value by -
                var rebelAff = rebelAff.split('-');
                // get affiliateID
                var affiliateID = rebelAff[0];
                // get clickRef
                var clickRef = rebelAff[1];
            }

            jQuery.ajax({
                url: ajax_object.ajaxurl,
                type: "post",
                data: {action: "post_rebel_customer", fullAddress: fullAddress, number: number, street: street, flat: flat, posttown: posttown, postcode: postcode, accountHolder: accountHolder, accountnumber: accountnumber, sortcode:sortcode, profileClass: profileClass, externalRef: externalRef, title:title, firstname:firstname, lastname:lastname, email:email, phone:phone, mpan:mpan, uprn:uprn, mprn:mprn, elecUsage:elecUsage, gasUsage:gasUsage, fuelType: fuelType, tariffType:tariffType, elecPaymentAmount:elecPaymentAmount, gasPaymentAmount:gasPaymentAmount, productCode:productCode, skipcooloff:skipcooloff, clickRef:clickRef, affiliateID:affiliateID},
                success: function(data, textStatus, xhr) {
                    if (xhr.status === 200) {
                            if (data.includes("BANK") && data.includes("account_number")) {
                                jQuery('.join-error').text('Bank account number has failed validation checks, please check and try again.');
                                jQuery('.join-error').show();
                                jQuery('#join-rebel-btn .fas').hide();
                            } else {
                        if (clickRef != undefined && clickRef != '' && clickRef != 'undefined' && clickRef != 'null' && clickRef != null && clickRef != 0 && clickRef != '0' && affiliateID == '006') {
                                if (rebel_fuels == 'dualFuel') {
                                    var postbackUrl = 'https://www.nng4trk.com/?nid=2554&event_id=356&transaction_id=' + clickRef;
                                } else {
                                    var postbackUrl = 'https://www.nng4trk.com/?nid=2554&transaction_id=' + clickRef;
                                }
                                    jQuery.ajax({
                                        type: "POST", // or "GET" depending on the type of request
                                        url: postbackUrl, // the URL where the request is sent
                                        success: function(response) {
                            
                                            var formname = 'quote-form';
                        
                                            if (submit_offer_form_rebel(formname) == true) {
                                            return;
                                            }
                                        },
                                        error: function(xhr, status, error) {
                            
                                        }
                                    });
                                
                        } else {
                            var formname = 'quote-form';
                
                            if (submit_offer_form_rebel(formname)) {
                                return;
                            }
                        }
                    }
                    }
                },
            });

        } 
    });

    function submit_offer_form_rebel(form_name, rebel_sale) {

        if (rebel_sale !== 'M') {
            rebel_sale = 'Y';
        }

        let data = {
            campid : jQuery('#' + form_name + ' #campid').val() || 0,
            sid : jQuery('#' + form_name + ' #sid').val() || 0,
            postcode : jQuery('#' + form_name + ' #postcodereg').val() || 0,
            ssid : jQuery('#' + form_name + ' #ssid').val() || 0,
            c1 : jQuery('#' + form_name + ' #c1').val() || 0,
            c2 : jQuery('#' + form_name + ' #c2').val() || 0,
            c3 : jQuery('#' + form_name + ' #c3').val() || 0,
            c4 : jQuery('#' + form_name + ' #c4').val() || 0,
            c5 : jQuery('#' + form_name + ' #c5').val() || 0,
            c6 : jQuery('#' + form_name + ' #c6').val() || 0,
            c7 : jQuery('#' + form_name + ' #c7').val() || 0,
            c8 : jQuery('#' + form_name + ' #c8').val() || 0,
            c9 : jQuery('#' + form_name + ' #c9').val() || 0,
            c10 : jQuery('#' + form_name + ' #c10').val() || 0,
            c11 : jQuery('#' + form_name + ' #c11').val() || 0,
            campid2 : jQuery('#' + form_name + ' #campid2').val() || 0,
            source : jQuery('#' + form_name + ' #source').val() || 0,
            title : jQuery('#' + form_name + ' #titlereg').val() || 0,
            firstname : jQuery('#' + form_name + ' #firstnamereg').val() || 0,
            lastname : jQuery('#' + form_name + ' #lastnamereg').val() || 0,
            email : jQuery('#' + form_name + ' #emailreg').val() || 0,
            gclid : jQuery('#' + form_name + ' #gclid').val() || 0,
            msclkid : jQuery('#' + form_name + ' #msclkid').val() || 0,
            phone1 : jQuery('#' + form_name + ' #phonereg').val() || 0,
            number : jQuery('#' + form_name + ' #numberreg').val() || 0,
            street1 : jQuery('#' + form_name + ' #streetreg').val() || 0,
            industry : jQuery('#' + form_name + ' #industry').val() || 0,
            dob : jQuery('#' + form_name + ' #dobyearreg').val() || 0,
            businessname: jQuery('#' + form_name + ' #businessname').val() || 0,
            optinurl : jQuery('#' + form_name + ' #optinurl').val() || 0,
            rebel_sale : rebel_sale,
            rebel_fuels : jQuery('#' + form_name + ' #fuelTypereg').val(),
            externalref : jQuery('#' + form_name + ' #externalref').val(),
            mpan : jQuery('#' + form_name + ' #mpanreg').val(),
            uprn : jQuery('#' + form_name + ' #uprnreg').val(),
            mprn : jQuery('#' + form_name + ' #mprnreg').val(),
            elecUsage : jQuery('#' + form_name + ' #elecUsagereg').val(),
            gasUsage : jQuery('#' + form_name + ' #gasUsagereg').val(),
            affiliateID : jQuery('#' + form_name + ' #rebel-affiliateID').val(),
            clickRef : jQuery('#' + form_name + ' #click_ref').val(),
            paymentAmountElec : jQuery('#' + form_name + ' #fixedamountElecreg').val(),
            paymentAmountGas : jQuery('#' + form_name + ' #fixedamountGasreg').val(),
            awc : jQuery('#' + form_name + ' #awc').val(),
            awcr : jQuery('#' + form_name + ' #awcr').val(),
            awpid : jQuery('#' + form_name + ' #awpid').val(),
            awbid : jQuery('#' + form_name + ' #awbid').val(),
            awgid : jQuery('#' + form_name + ' #awgid').val(),
            awaid : jQuery('#' + form_name + ' #awaid').val()
        }

        var rebel_fuels = jQuery('#fuelTypereg').val();
        var externalRef = jQuery('#externalref').val();
        var clickRef = jQuery('#click_ref').val();
        var email = jQuery('#emailreg').val();
        var gclid = jQuery('#gclid').val();
        var msclkid = jQuery('#msclkid').val();

        jQuery.ajax({
            url: 'https://api.switcheroo.deals/leadbyte-form-submit-v4.php?' + 'campid='+data.campid +'&sid=' + data.sid + '&postcode=' + data.postcode + '&ssid=' + data.ssid + '&c1=' + data.c1 + '&c2=' + data.c2 + '&c3=' + data.c3 + '&c4=' + data.c4 + '&c5=' + data.c5 + '&c6=' + data.c6 + '&c7=' + data.c7 + '&c8=' + data.c8 + '&c9=' + data.c9 + '&c10=' + data.c10 + '&c11=' + data.c11 + '&campid2=' + data.campid2 + '&source=' +  data.source + '&firstname=' + data.firstname + '&lastname=' + data.lastname + '&email=' + data.email + '&gclid=' + data.gclid + '&msclkid=' + data.msclkid + '&phone1=' + data.phone1 + '&street1=' + data.number + ',' + data.street1 + '&number=' + data.number + '&industry=' + data.industry + '&dob=' + data.dob + '&company=' + data.businessname + '&optinurl=' + data.optinurl + '&rebel_sale=' + data.rebel_sale + '&rebel_fuels=' + data.rebel_fuels + '&rebelexternalref=' + data.externalref + '&mpan=' + data.mpan + '&uprn=' + data.uprn + '&mprn=' + data.mprn + '&elecUsage=' + data.elecUsage + '&gasUsage=' + data.gasUsage + '&title=' + data.title + '&affiliateID=' + data.affiliateID + '&clickRef=' + data.clickRef + '&paymentAmountElectricity=' + data.paymentAmountElec + '&paymentAmountGas=' + data.paymentAmountGas + '&awc=' + data.awc + '&awcr=' + data.awcr + '&awpid=' + data.awpid + '&awbid=' + data.awbid + '&awgid=' + data.awgid + '&awaid=' + data.awaid,
            type: 'POST',
            enctype: 'multipart/form-data',
            data: data,
            success : function (response) {

                window.location.replace('https://switcheroo.co.uk/comparison-complete/consumer-energy-rebel-thank-you/?fuelType=' + rebel_fuels + '&externalRef=' + externalRef + '&clickRef=' + clickRef + '&email=' + email + '&gclid=' + gclid + '&msclkid=' + msclkid + '&awc=' + data.awc + '&awcr=' + data.awcr + '&awpid=' + data.awpid + '&awbid=' + data.awbid + '&awgid=' + data.awgid + '&awaid=' + data.awaid + '&uprn=' + data.uprn);
                jQuery('.rebel-personal-details').hide();
                return true;

            },error : function (response) {
                return false;
            }});
        
    }

    // if .rebel-gen-quote is visible on the page, then click it
    if (jQuery('.rebel-gen-quote-next').is(':visible')) {

            var queryString = window.location.search.substring(1);
        
            // Split the query string into an array of key-value pairs
            var queryParams = queryString.split("&");
        
            // Create an object to store the parameter-value pairs
            var params = {};
            queryParams.forEach(function(param) {
              var pair = param.split("=");
              if (pair.length === 2) {
                var key = decodeURIComponent(pair[0]);
                var value = decodeURIComponent(pair[1]);
                params[key] = value;
              }
            });

            if (params['fueltype'] !== undefined ||  params['fuelType'] !== undefined) {

            if (params['fueltype'] == 'dualFuel' || params['fuelType'] == 'dualFuel' || params['fueltype'] == 'BOTH GAS' || params['fueltype'] == 'BOTH ELECTRICITY' || params['fueltype'] == 'BOTH GAS AND ELECTRICITY') {
                jQuery('.choose-box').removeClass('checked');
                jQuery('.rebel-energy-options .choose-box:eq(0)').addClass('checked');
                jQuery('.energy-checkbox div').removeClass('checked');
                jQuery('.rebel-energy-options .choose-box:eq(0)').find('.energy-checkbox div').addClass('checked');
                jQuery('#reb-step1-btn').css('opacity', 1);
                jQuery("#reb-step1-btn").prop("disabled", false);
    
            } else {
            
                jQuery('.choose-box').removeClass('checked');
                jQuery('.rebel-energy-options .choose-box:eq(1)').addClass('checked');
                jQuery('.energy-checkbox div').removeClass('checked');
                jQuery('.rebel-energy-options .choose-box:eq(1)').find('.energy-checkbox div').addClass('checked');
                jQuery('#reb-step1-btn').css('opacity', 1);
                jQuery("#reb-step1-btn").prop("disabled", false);
        
            }
        }
        
    }

    jQuery('#update-quote').on('click', function() {
        var fuelType = jQuery('#fuelTypereg').val();

        var elecConsumption = parseFloat(jQuery('#elec-consumption-value').val());
        var gasConsumption = parseFloat(jQuery('#gas-consumption-value').val());

         // Fixed Electric Calculations

        var fixElecPrice = parseFloat(jQuery('#tariff-details-panel p:eq(5)').text().replace(/[^\d.]+/g, ''));
            var fixElecCost = (elecConsumption * fixElecPrice) / 100;
                
        var fixElecStand = parseFloat(jQuery('#tariff-details-panel p:eq(6)').text().replace(/[^\d.]+/g, ''));
        var fixElecStandCost = (fixElecStand * 365) / 100;
            fixElecStandCost = fixElecStandCost.toFixed(2);

                var fixTotalElec = parseFloat(fixElecCost) + parseFloat(fixElecStandCost);
                fixTotalElec = parseFloat(fixTotalElec);
        
                // Fixed Gas Calculations
        
                var fixGasPrice = parseFloat(jQuery('#tariff-details-panel p:eq(7)').text().replace(/[^\d.]+/g, ''));
                var fixGasCost = (gasConsumption * fixGasPrice) / 100;
                
                var fixGasStand = parseFloat(jQuery('#tariff-details-panel p:eq(8)').text().replace(/[^\d.]+/g, ''));
                var fixGasStandCost = (fixGasStand * 365) / 100;
                fixGasStandCost = fixGasStandCost.toFixed(2);
                
                var fixTotalGas = parseFloat(fixGasCost) + parseFloat(fixGasStandCost);
                fixTotalGas = parseFloat(fixTotalGas);
        
                // Fixed Total
        
                if (fuelType == 'dualFuel') {
                    var fixTotal = (fixTotalElec + fixTotalGas).toFixed(2);
                    var fixMonthly = (fixTotal / 12).toFixed(2);
                } else {
                    var fixTotal = fixTotalElec.toFixed(2);
                    var fixMonthly = (fixTotal / 12).toFixed(2);
                }
        
                jQuery('.rebel-price h2').text("£" + fixMonthly);
                jQuery('.annual-price p').text("Based on your estimated consumption at an annual rate of £" + fixTotal + " (Incl. VAT)");

                //jQuery('.details-overview p:nth-child(1) span').text('£' + fixMonthly);

                fixedTariffTotalMonthly = fixMonthly;

                var fixedElement = jQuery('.fixed-tariff');

                var newElecConsumption = jQuery('#elec-consumption-value').val();
                jQuery('#elecUsagereg').val(newElecConsumption);

                var newGasConsumption = jQuery('#gas-consumption-value').val();
                jQuery('#gasUsagereg').val(newGasConsumption);

                var fixedAmountElec = (fixTotalElec / 12).toFixed(2);
                    fixedAmountElec = parseFloat(fixedAmountElec);
                var fixedAmountGas = (fixTotalGas / 12).toFixed(2);
                    fixedAmountGas = parseFloat(fixedAmountGas);

                jQuery('#fixedamountElecreg').val(fixedAmountElec);
                jQuery('#fixedamountGasreg').val(fixedAmountGas);

    });

    var imageState = "plus";
    jQuery('.mobile-quote-consumption').on('click', function() {
        jQuery('.quote-consumption').toggle();
        var imgElement = jQuery(this).find(".toggle-img");

        // Toggle the image source based on the current state
        if (imageState === "plus") {
          imgElement.attr("src", "https://switcheroo.co.uk/wp-content/uploads/2023/08/minus.png");
          imageState = "minus"; // Update the state
        } else {
          imgElement.attr("src", "https://switcheroo.co.uk/wp-content/uploads/2023/08/plus.png");
          imageState = "plus"; // Update the state
        }
    });

    jQuery('.rebel-back').on('click', function() {
        var tostage = jQuery(this).attr('id');
        if (tostage == 'tosupply') {
            jQuery('.rebel-housesize').hide();
            jQuery('.rebel-got-quote').hide();
            jQuery('.rebel-energy-options').show();
        }
        if (tostage == 'toquote') {
            jQuery('.rebel-personal-details').hide();
            jQuery('.rebel-got-quote').show();
        }
        if (tostage == 'topersonal') {
            jQuery('.rebel-consent').hide();
            jQuery('.rebel-personal-details').show();
        }
        if (tostage == 'toconsent') {
            jQuery('.rebel-payment-details').hide();
            jQuery('.rebel-consent').show();
        }
    });

    function generateUniqueKey() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let key = '';
        
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            key += characters.charAt(randomIndex);
        }
        
        return key;
    }

    jQuery('#add-bedroom').on('click', function() {
        var bedrooms = jQuery('#nobedrooms').text();
        var nobedrooms = parseInt(jQuery('#nobedrooms').find('span').text());
        if (nobedrooms <= 14) {
            jQuery('#nobedrooms').find('span').text(nobedrooms + 1);
            var newbed = nobedrooms + 1;
            jQuery('#bed' + newbed).show();
        }
    });

    jQuery('#remove-bedroom').on('click', function() {
        var bedrooms = jQuery('#nobedrooms').text();
        var nobedrooms = parseInt(jQuery('#nobedrooms').find('span').text());
        if (nobedrooms >= 2) {
            jQuery('#nobedrooms').find('span').text(nobedrooms - 1);
            var newbed = nobedrooms - 1;
            jQuery('#bed' + newbed).hide();
        }
    });

    jQuery('.details-btn').on('click', function() {
        var panel = jQuery(this).next('.tariff-details-panel');
        var plusimg = jQuery(this).find('.plus-img');
        var minusimg = jQuery(this).find('.minus-img');
        if (jQuery(panel).is(':hidden')) {
            panel.css('display', 'flex');
            plusimg.hide();
            minusimg.show();
        } else {
            panel.css('display', 'none');
            plusimg.show();
            minusimg.hide();
        }
    });

    function getDaySuffix(day) {
        if (day >= 11 && day <= 13) {
            return 'th';
        }
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    function formatDate(dateString) {
        var date = new Date(dateString);
    
        var daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
        var dayOfWeek = daysOfWeek[date.getUTCDay()];
        var day = date.getUTCDate();
        var month = months[date.getUTCMonth()];
        var year = date.getUTCFullYear();
    
        var suffix = getDaySuffix(day); // Make sure to define the getDaySuffix function
    
        var formattedDate = dayOfWeek + ' ' + day + suffix + ' ' + month + ' ' + year;
        
        return formattedDate;
    }
    
    // Check if the 'redirectJB' parameter exists and has a value of 'yes'
    if (urlParams.has('redirectJB') && urlParams.get('redirectJB') === 'yes') {
        jQuery('.rebel-personal-details h1').text('Please confirm your details');
        jQuery('.rebel-energy-options').hide();
        jQuery('.rebel-compare-loader > .wrapper > .rebel-loader-box > p').text('Comparing energy tariffs...');
        jQuery('.rebel-gen-quote').click();
    }

    function generateRandomString(length) {
        const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let randomString = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    }
    
});

// Rebel Price Scroll Down
jQuery(document).on("click", ".rebel-price#scrollDown", function(){
    var jQueryelement = jQuery('.site-main');
    jQuery('html, body').animate({
        scrollTop: jQueryelement.offset().top + jQueryelement.outerHeight() - jQuery(window).height()
    }, 1000);
});